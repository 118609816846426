$max-width: 920px;
// breakpoint
$sm: 600px;

$global-margin: 120px;
$global-padding: $global-margin;
$sm-global-margin: 20px;
$sm-global-padding: $sm-global-margin;
// white
// $primary-color:  #beb07c;
// $gradient-start-color: #e0d6b2;
// $secondary-color: #ffffff;
// dark
$primary-color: #27241a;
$gradient-start-color: #3a362a;
$secondary-color: #333333;
$text-color: #dddbd1;
$description-color: #807b6e;
$small-margin: 5px;
$active-color: #d6c792;
$base-font-size: 0.9rem;

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  activeColor: $active-color;
  textColor: $text-color;
}
