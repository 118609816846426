@import 'reset';
@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Hind+Vadodara:400,600&display=swap');
html {
  background: radial-gradient($gradient-start-color, $primary-color 100%);
}
html,
body,
#root {
  font-family: 'Hind Vadodara', sans-serif;
  scroll-behavior: smooth;
  letter-spacing: 2px;
  width: 100%;
  height: 100%;
  // background-color: $primary-color;
  // background: linear-gradient(45deg, rgba($primary-color,1) 0%, rgba(#093f6f,1) 100%);
  // background: radial-gradient(#e5dcbc, $primary-color 100%);

  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  overflow: hidden;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.style-reset-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  color: $text-color;
  letter-spacing: 2px;
}

.index-footer-text {
  position: relative;
  z-index: 10;
  bottom: 10%;
  line-height: 0.9rem;
  padding: 0 15px;
  letter-spacing: 2px;
  left: 0;
  text-align: center;
  font-size: 0.8rem;
  color: rgba($color: #ffffff, $alpha: 0.8);
}
.canvas {
  height: 100%;
}
a {
  color: rgba($text-color, 1);
}
////////////////////
// header
////////////////////
.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  height: 60px;
  width: 100%;
  z-index: 100;
  color: $text-color;
  .logo {
    float: left;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: 400;
    a {
      color: rgba($text-color, 1);
      text-decoration: none;
    }
  }
  .nav {
    font-size: 0.8rem;
    position: absolute;
    top: $sm-global-margin;
    right: $sm-global-margin;
    li {
      float: left;
      margin-left: 20px;
      cursor: pointer;
      a {
        text-decoration: none;
      }
    }
  }
}
////////////////////
// loader
////////////////////
.loader-container {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient($gradient-start-color, $primary-color 100%);
  &__inner {
    padding: $sm-global-padding;
    .loader-img {
      width: 70px;
      margin-left: -35px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

////////////////////
// scroll
////////////////////
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($text-color, 0.25);
}

////////////////////
// contents
////////////////////
.contents {
  // background: #43465a;
  // margin-top: $global-margin / 2;
  background: $primary-color;
  // background: $secondary-color;
  color: $text-color;
  // color: rgba($color: #ffffff, $alpha: 1);
  position: fixed;
  left: 0;
  top: 0;
  line-height: 2rem;
  z-index: 99;
  width: 100%;
  height: 100%;
  white-space: pre-line; // i18n
  .scroll-area {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  // inner
  &__inner {
    height: 100%;
    padding: $global-margin;
    max-width: $max-width;
    margin: 0 auto;
  }
  &__title {
    display: inline-block;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: $global-margin;
  }
  &__footer {
    margin-bottom: $global-margin / 2;
    height: $global-margin / 2;
    font-size: 0.7rem;
    display: flex;
    &-copyright {
      margin-right: 10px;
    }
  }
  &__cover {
    margin-bottom: $global-margin;
    .message {
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.5rem;
      span {
        font-size: 0.9rem;
        display: block;
        font-weight: normal;
      }
    }
  }
  .back-btn {
    position: absolute;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 20;
    bottom: 80px;
    right: 0px;
    font-size: 0.7rem;
    writing-mode: vertical-rl;
    padding: 30px 5px;
    background: $text-color;
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
  }
  // paragraph
  .paragraph {
    margin-bottom: $global-margin;
    &__title {
      font-size: 1rem;
      font-weight: bold;
      line-height: 0.9rem;
      margin-bottom: $global-margin / 5;
    }
    p,
    ul,
    dl {
      font-weight: normal;
      font-size: $base-font-size;
      line-height: 1.8rem;
    }
    p {
      margin-bottom: $global-margin / 2;
    }
    li {
      // background: url(../images/list_box.png) no-repeat;
    }
    .box-list {
      display: flex;
      width: 47%;
      float: left;
      margin-bottom: 3%;
    }
    .box-list:nth-child(even) {
      margin-left: 3%;
    }
    .box-list__body,
    .box-list__start,
    .box-list__end {
      font-size: 0.7rem;
      text-align: center;
      line-height: 1.3rem;
      padding-top: 5px;
      height: 40px;
    }

    .box-list__body {
      background: url(../images/box_list_body.png) repeat-x;
      width: 100%;
    }
    .box-list__start {
      background: url(../images/box_list_start.png) no-repeat;
      width: 11px;
    }
    .box-list__end {
      background: url(../images/box_list_end.png) no-repeat;
      width: 13px;
    }
    dl {
      margin-bottom: $global-margin;
    }
    dt {
      font-weight: normal;
      font-size: 1rem;
      margin-bottom: $global-margin / 5;
    }
    dd {
      margin-bottom: $global-margin / 5;
    }
    .blockquote {
      position: relative;
      max-width: 95%;
      padding: 30px 15px 15px 15px;
      border-left: 0.4em solid;
      // background-color: rgba($color: $text-color, $alpha: .01);
      border-color: rgba($color: $text-color, $alpha: 0.1);
      p {
        margin-bottom: 15px;
      }
      &:before {
        font-family: 'Sanchez', serif;
        font-size: 4rem;
        line-height: 1rem;
        color: rgba($color: $text-color, $alpha: 0.1);
        content: '\201C';
        position: absolute;
        top: 13px;
        left: 10px;
      }
      // &:after {
      //   font-family: 'Sanchez', serif;
      //   line-height: 1rem;
      //   color: rgba($color: $text-color, $alpha: 0.1);
      //   content: '\201D';
      //   position: absolute;
      //   bottom: -25px;
      //   right: 20px;
      //   font-size: 4rem;
      //   font-style: italic;
      // }
      footer {
        font-size: $base-font-size;
        font-style: italic;
        color: rgba($color: $text-color, $alpha: 0.6);
      }
    }
  }
  // lang
  .lang-change {
    font-size: 1rem;
    font-weight: normal;
    margin-left: 10px;
  }
  .lang-change span {
    cursor: pointer;
    padding: 2px;
    text-decoration: underline;
  }
  .lang-change span:nth-child(even) {
    margin-left: 10px;
  }
  // cover
  .cover {
    margin-bottom: $global-margin;
  }
}

// safari
_::-webkit-full-page-media,
_:future,
:root .back-btn {
  right: 10px;
}
// firefox
.contents {
  scrollbar-color: #2d2f3b #43465a;
}

////////////////////
// contact
////////////////////
.contact {
  margin-bottom: $global-margin;
  &-links {
    display: flex;
  }
  &-links__item {
    padding: 0 5px;
    margin-bottom: $global-margin / 2;
    line-height: 18px;
    font-size: 0.9rem;
    & a {
      text-decoration: none;
    }
  }
  &-links__item:not(:last-child) {
    background: url('../images/slash.png') no-repeat;
    background-position: center right;
    padding-right: 25px;
  }
}

////////////////////
// float
////////////////////
.float-left {
  float: left;
}

////////////////////
// media screen sm
////////////////////
@media screen and (max-width: $sm) {
  .header {
    padding: $sm-global-padding;
    // height: 80px;
    .nav {
      right: $sm-global-margin;
    }
  }
  .contents {
    &__title {
      margin-bottom: $global-margin;
    }
    &__inner {
      padding: $global-margin $sm-global-padding * 1.5;
    }
    .back-btn {
      bottom: 50px;
      font-size: 0.7rem;
      padding: 30px 0px;
    }
    .paragraph {
      li {
        margin-bottom: 5px;
      }
      li:nth-child(odd) {
        margin-right: 5px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}

// cookie
.cookie-message {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: #888;
  font-size: 0.7rem;
  padding: 10px;
  width: 100%;
  display: flex;
  &__body {
    margin-right: 10px;
    a {
      color: #888;
    }
  }
  &__btn {
    background: #444;
    padding: 0px 4px;
    border-radius: 2px;
    color: $text-color;
    cursor: pointer;
  }
}
