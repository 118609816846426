@import url(https://fonts.googleapis.com/css?family=Hind+Vadodara:400,600&display=swap);


*,
*::before,
*::after {
  box-sizing: border-box; }

ul[class],
ol[class] {
  padding: 0; }

html,
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0; }

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

ul[class],
ol[class] {
  list-style: none; }

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block; }

article > * + * {
  margin-top: 1em; }

input,
button,
textarea,
select {
  font: inherit; }

* {
  box-sizing: border-box; }

html {
  background: radial-gradient(#3a362a, #27241a 100%); }

html,
body,
#root {
  font-family: 'Hind Vadodara', sans-serif;
  scroll-behavior: smooth;
  letter-spacing: 2px;
  width: 100%;
  height: 100%;
  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  overflow: hidden; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

.clearfix::after {
  content: '';
  display: block;
  clear: both; }

.style-reset-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  color: #dddbd1;
  letter-spacing: 2px; }

.index-footer-text {
  position: relative;
  z-index: 10;
  bottom: 10%;
  line-height: 0.9rem;
  padding: 0 15px;
  letter-spacing: 2px;
  left: 0;
  text-align: center;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8); }

.canvas {
  height: 100%; }

a {
  color: #dddbd1; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  height: 60px;
  width: 100%;
  z-index: 100;
  color: #dddbd1; }
  .header .logo {
    float: left;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: 400; }
    .header .logo a {
      color: #dddbd1;
      text-decoration: none; }
  .header .nav {
    font-size: 0.8rem;
    position: absolute;
    top: 20px;
    right: 20px; }
    .header .nav li {
      float: left;
      margin-left: 20px;
      cursor: pointer; }
      .header .nav li a {
        text-decoration: none; }

.loader-container {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#3a362a, #27241a 100%); }
  .loader-container__inner {
    padding: 20px; }
    .loader-container__inner .loader-img {
      width: 70px;
      margin-left: -35px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      left: 50%; }

::-webkit-scrollbar {
  width: 8px; }

::-webkit-scrollbar-thumb {
  background-color: rgba(221, 219, 209, 0.25); }

.contents {
  background: #27241a;
  color: #dddbd1;
  position: fixed;
  left: 0;
  top: 0;
  line-height: 2rem;
  z-index: 99;
  width: 100%;
  height: 100%;
  white-space: pre-line; }
  .contents .scroll-area {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
  .contents__inner {
    height: 100%;
    padding: 120px;
    max-width: 920px;
    margin: 0 auto; }
  .contents__title {
    display: inline-block;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 120px; }
  .contents__footer {
    margin-bottom: 60px;
    height: 60px;
    font-size: 0.7rem;
    display: flex; }
    .contents__footer-copyright {
      margin-right: 10px; }
  .contents__cover {
    margin-bottom: 120px; }
    .contents__cover .message {
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.5rem; }
      .contents__cover .message span {
        font-size: 0.9rem;
        display: block;
        font-weight: normal; }
  .contents .back-btn {
    position: absolute;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 20;
    bottom: 80px;
    right: 0px;
    font-size: 0.7rem;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    padding: 30px 5px;
    background: #dddbd1;
    color: #27241a;
    cursor: pointer;
    text-decoration: none; }
  .contents .paragraph {
    margin-bottom: 120px; }
    .contents .paragraph__title {
      font-size: 1rem;
      font-weight: bold;
      line-height: 0.9rem;
      margin-bottom: 24px; }
    .contents .paragraph p,
    .contents .paragraph ul,
    .contents .paragraph dl {
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 1.8rem; }
    .contents .paragraph p {
      margin-bottom: 60px; }
    .contents .paragraph .box-list {
      display: flex;
      width: 47%;
      float: left;
      margin-bottom: 3%; }
    .contents .paragraph .box-list:nth-child(even) {
      margin-left: 3%; }
    .contents .paragraph .box-list__body,
    .contents .paragraph .box-list__start,
    .contents .paragraph .box-list__end {
      font-size: 0.7rem;
      text-align: center;
      line-height: 1.3rem;
      padding-top: 5px;
      height: 40px; }
    .contents .paragraph .box-list__body {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAoCAYAAADHVmuAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjIwQzUxM0E1REREMTFFQjlBOUJBNzU5Qzk5N0Q4QUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjIwQzUxM0I1REREMTFFQjlBOUJBNzU5Qzk5N0Q4QUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5QzgzM0U4OTVEREIxMUVCOUE5QkE3NTlDOTk3RDhBQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5QzgzM0U4QTVEREIxMUVCOUE5QkE3NTlDOTk3RDhBQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhoMzxsAAAA4SURBVHjaYowKdf7PQARgVFeRIkohEwORYFThqMJRhaMKB7VCFmNDTeIUgsrIkRc8QExUrQAQYAD1YwU3SnxT3gAAAABJRU5ErkJggg==) repeat-x;
      width: 100%; }
    .contents .paragraph .box-list__start {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAoCAYAAADHVmuAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OUM4MzNFODc1RERCMTFFQjlBOUJBNzU5Qzk5N0Q4QUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUM4MzNFODg1RERCMTFFQjlBOUJBNzU5Qzk5N0Q4QUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5QzgzM0U4NTVEREIxMUVCOUE5QkE3NTlDOTk3RDhBQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5QzgzM0U4NjVEREIxMUVCOUE5QkE3NTlDOTk3RDhBQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoLDQRIAAACOSURBVHjaYowKdf7PQARgARFnz18nqJCJgUgwqnBU4ajCUYWDWiG42DM21CTKRDsgfgrE1VA+IzYMkjgMxOZA7A3E64GYD58bQSY6APETID4FxFr4PPMbiHOAuA2IDwBxGIZn0MAiIL4ExGuB2BSIK4H4D67guQBVpA3Eu4BYFF84vgNiHyA+CsQvAQIMAN9QF6F7iu6LAAAAAElFTkSuQmCC) no-repeat;
      width: 11px; }
    .contents .paragraph .box-list__end {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAoCAYAAAAolAC+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjIwQzUxNDI1REREMTFFQjlBOUJBNzU5Qzk5N0Q4QUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjIwQzUxNDM1REREMTFFQjlBOUJBNzU5Qzk5N0Q4QUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMjBDNTE0MDVEREQxMUVCOUE5QkE3NTlDOTk3RDhBQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMjBDNTE0MTVEREQxMUVCOUE5QkE3NTlDOTk3RDhBQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PthLhU4AAADvSURBVHja7NUxa8JAGMbxS3BRQSkozq4FQXARV6d2qQqCOFZFP4Wb6AcQMW1RXPoBOhY3N9cuzu6iiHYRqv+TC3RJ7lYxL/wIhIe7917CxapXi2chRA8DHIRP2eqZwgqv/955hluooIMFMn5hWUvkMcW3aiviFZb1hw88IoEfPHuF3dqiiTaGmOHBFv41V/3vZJu6sKwjukiahIWu5yB8I+GQafiEqGn4N5jz3YbfUNB+drAQwzuyGOlW3qOGOCYI63qWv4s+PvGFtMkB5c3ZwBhPJtNY4wVldYNa7gFzPgNwUFK7XcOOwYij2FwEGAB3dyVk2niNJgAAAABJRU5ErkJggg==) no-repeat;
      width: 13px; }
    .contents .paragraph dl {
      margin-bottom: 120px; }
    .contents .paragraph dt {
      font-weight: normal;
      font-size: 1rem;
      margin-bottom: 24px; }
    .contents .paragraph dd {
      margin-bottom: 24px; }
    .contents .paragraph .blockquote {
      position: relative;
      max-width: 95%;
      padding: 30px 15px 15px 15px;
      border-left: 0.4em solid;
      border-color: rgba(221, 219, 209, 0.1); }
      .contents .paragraph .blockquote p {
        margin-bottom: 15px; }
      .contents .paragraph .blockquote:before {
        font-family: 'Sanchez', serif;
        font-size: 4rem;
        line-height: 1rem;
        color: rgba(221, 219, 209, 0.1);
        content: '\201C';
        position: absolute;
        top: 13px;
        left: 10px; }
      .contents .paragraph .blockquote footer {
        font-size: 0.9rem;
        font-style: italic;
        color: rgba(221, 219, 209, 0.6); }
  .contents .lang-change {
    font-size: 1rem;
    font-weight: normal;
    margin-left: 10px; }
  .contents .lang-change span {
    cursor: pointer;
    padding: 2px;
    text-decoration: underline; }
  .contents .lang-change span:nth-child(even) {
    margin-left: 10px; }
  .contents .cover {
    margin-bottom: 120px; }

_::-webkit-full-page-media,
_:future,
:root .back-btn {
  right: 10px; }

.contents {
  scrollbar-color: #2d2f3b #43465a; }

.contact {
  margin-bottom: 120px; }
  .contact-links {
    display: flex; }
  .contact-links__item {
    padding: 0 5px;
    margin-bottom: 60px;
    line-height: 18px;
    font-size: 0.9rem; }
    .contact-links__item a {
      text-decoration: none; }
  .contact-links__item:not(:last-child) {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3VpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo2ZjkxMmM5ZS0zMDBjLTRjMmEtOTc2Mi0zN2RiMjBiYTc1MTMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzE0MDcxNkY2MDNBMTFFQjhFNjhENTJGNUQyNTJBNkIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzE0MDcxNkU2MDNBMTFFQjhFNjhENTJGNUQyNTJBNkIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4MGYxYmYyNy02ZjBlLTRiMWQtYmYwOC1iOGNmYmQ1MzllNWQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NmY5MTJjOWUtMzAwYy00YzJhLTk3NjItMzdkYjIwYmE3NTEzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+zVnXMQAAAKlJREFUeNpi/P//PwM1ABMDlQDtDGJkZMSJgaAciEOxmgQKI2SMCwDllIB4NzY9YH0kGDQTiI1xGURUGAEVukC9fRafIoIuAoqvAnkNlx6iXARUlAakzgJdc4+QQrwuAoqdAWJBfHoIugioABTds4CueU9MQGJ1EcgVINcQowdv9EOj24Uig5ATH6UG7UaObmIMYsKR+O4RjG4iTC9Hj25iXMRIrYINIMAAeLFJQheU8NwAAAAASUVORK5CYII=) no-repeat;
    background-position: center right;
    padding-right: 25px; }

.float-left {
  float: left; }

@media screen and (max-width: 600px) {
  .header {
    padding: 20px; }
    .header .nav {
      right: 20px; }
  .contents__title {
    margin-bottom: 120px; }
  .contents__inner {
    padding: 120px 30px; }
  .contents .back-btn {
    bottom: 50px;
    font-size: 0.7rem;
    padding: 30px 0px; }
  .contents .paragraph li {
    margin-bottom: 5px; }
  .contents .paragraph li:nth-child(odd) {
    margin-right: 5px; }
  ::-webkit-scrollbar {
    width: 5px; } }

.cookie-message {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: #888;
  font-size: 0.7rem;
  padding: 10px;
  width: 100%;
  display: flex; }
  .cookie-message__body {
    margin-right: 10px; }
    .cookie-message__body a {
      color: #888; }
  .cookie-message__btn {
    background: #444;
    padding: 0px 4px;
    border-radius: 2px;
    color: #dddbd1;
    cursor: pointer; }

